var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("עדכון סטטוס ליחידות לימוד")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body", staticStyle: { height: "500px" } },
        [
          _c(
            "div",
            { staticClass: "DialogReportPaid__Component__Loading" },
            [
              _c(
                "b-field",
                { attrs: { label: "סטטוס" } },
                [
                  _c(
                    "b-checkbox",
                    {
                      staticClass: "checkbox",
                      attrs: { label: "", type: "is-link is-light" },
                      model: {
                        value: _vm.value,
                        callback: function($$v) {
                          _vm.value = $$v
                        },
                        expression: "value"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.value ? "פעיל" : "לא פעיל") + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }