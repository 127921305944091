/* eslint-disable consistent-return */
import {
    DialogProgrammatic as Dialog,
    ToastProgrammatic as Toast,
    ModalProgrammatic as Modal,
    LoadingProgrammatic as Loading,
} from 'buefy';
import AssignGroupsToLessons from '@/components/AssignGroupsToLessons.vue';
import AssignGroupsToStudents from '@/components/AssignGroupsToStudents.vue';
import SetColorToLessons from '@/components/SetColorToLessons.vue';
import SetStatusToLessons from '@/components/SetStatusToLessons.vue';
import SetStatusToExercises from '@/components/SetStatusToExercises.vue';
import SetHiddenToLessons from '@/components/SetHiddenToLessons.vue';
import SetTypeToExercises from '@/components/SetTypeToExercises.vue';
import store from '../store';
import LessonService from './LessonService';
import CoursesService from './CoursesService';

const course = JSON.parse(localStorage.getItem('course'));

const actions = {
    deleteReports: {
        Id: 'DeleteReports',
        Label: 'מחיקת דוחות',
        icon: 'trash-can',
        click: (reports, onSuccess) => {
            Dialog.confirm({
                message: 'האם ברצונך למחוק את הדוחות מהמערכת?',
                confirmText: 'כן',
                cancelText: 'לא',
            })
                .then(({ result }) => {
                    if (result) {
                        const loading = Loading.open({
                            isFullPage: true,
                        });
                        CoursesService
                            .deleteReports(reports.map((r) => r.id))
                            .then(() => {
                                // if (onSuccess) onSuccess();
                                window.location.reload();
                                Toast.open({
                                    type: 'is-success',
                                    message: 'הדוחות נמחקו בהצלחה!',
                                    duration: 4000,
                                });
                            })
                            .catch(() => {
                                Toast.open({
                                    type: 'is-danger',
                                    message: 'לא היה ניתן לבצע את הפעולה, נסה למחוק דוחות שוב.',
                                    duration: 8000,
                                });
                            })
                            .finally(() => {
                                loading.close();
                            });
                    }
                });
        },
    },
    assignGroupsToLessons: {
        Id: 'AssignGroupsToLessons',
        Label: 'שיוך קבוצות ליחידות לימוד',
        icon: 'cash-check',
        click: (groups, onSuccess) => {
            Modal.open({
                component: AssignGroupsToLessons,
                props: {
                    groups: groups.map((r) => r.id),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    assignGroupsToStudents: {
        Id: 'AssignGroupsToStudents',
        Label: 'שיוך תלמידים לקבוצה',
        icon: 'credit-card-check',
        click: (groups, onSuccess) => {
            Modal.open({
                component: AssignGroupsToStudents,
                props: {
                    groups: groups.map((r) => r.id),
                    onSuccess,
                    store
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    assignEscortsToStudents: {
        Id: 'AssignEscortsToStudents',
        Label: 'שיוך מלווים לקבוצה',
        icon: 'credit-card-check',
        click: (groups, onSuccess) => {
            Modal.open({
                component: AssignGroupsToStudents,
                props: {
                    groups: groups.map((r) => r.id),
                    onSuccess,
                    store,
                    isEscort: true
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    setLessonColor: {
        Id: 'setLessonColor',
        Label: 'עדכון צבע ליחידות לימוד',
        icon: 'border-color',
        click: (lessonIds, onSuccess) => {
            Modal.open({
                component: SetColorToLessons,
                props: {
                    lessonIds: lessonIds.map((r) => r.id),
                    onSuccess,
                    store,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    setLessonStatus: {
        Id: 'setLessonStatus',
        Label: 'עדכון סטטוס ליחידות לימוד',
        icon: 'list-status',
        click: (lessonIds, onSuccess) => {
            Modal.open({
                component: SetStatusToLessons,
                props: {
                    lessonIds: lessonIds.map((r) => r.id),
                    onSuccess,
                    store,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    setLessonHidden: {
        Id: 'setLessonHidden',
        Label: 'עדכון מוסתר ליחידות לימוד',
        icon: 'eye-check-outline',
        click: (lessonIds, onSuccess) => {
            Modal.open({
                component: SetHiddenToLessons,
                props: {
                    lessonIds: lessonIds.map((r) => r.id),
                    onSuccess,
                    store,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    setExerciseStatus: {
        Id: 'setExerciseStatus',
        Label: 'עדכון סטטוס לתרגולים',
        icon: 'list-status',
        click: (exerciseIds, onSuccess) => {
            Modal.open({
                component: SetStatusToExercises,
                props: {
                    exerciseIds: exerciseIds.map((r) => r.id),
                    onSuccess,
                    store,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    setExerciseType: {
        Id: 'setExerciseType',
        Label: 'עדכון סוג לתרגולים',
        icon: 'list-status',
        click: (exerciseIds, onSuccess) => {
            Modal.open({
                component: SetTypeToExercises,
                props: {
                    exerciseIds: exerciseIds.map((r) => r.id),
                    onSuccess,
                    store,
                },
                canCancel: false,
                hasModalCard: true,
                trapFocus: true,
            });
        },
    },
    deleteGroups: {
        Id: 'DeleteGroups',
        Label: 'מחיקת קבוצות',
        icon: 'flag-variant-outline',
        click: (groups, onSuccess) => {
            Dialog.confirm({
                message: 'האם אתה בטוח שברצונך למחוק את הקבוצות המסומנות?',
                onConfirm: () => {
                    LessonService.deleteGroups(course.id, {
                        groupIds: groups.map((g) => g.id)
                    }).then(() => {
                        Toast.open({
                            type: 'is-success',
                            message: 'המחיקה בוצעה בהצלחה',
                            duration: 10000,
                        });
                        if (onSuccess) onSuccess();
                    });
                },
                confirmText: 'בטוח!',
                cancelText: 'בטל',
            });
        },
    },
};

export default actions;
