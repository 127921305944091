<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">עדכון צבע ליחידות לימוד</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section style="height: 500px" class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
          <b-field label="בחירת צבע">
            <input type="color" v-model="valueStr">
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import LessonService from "@/services/LessonService";
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "AssignGroupsToStudents",
  props: ["lessonIds", "onSuccess", "store"],
  components: {
    // vSelect,
  },
  computed: {
    courseId() {
      return this.store.state.auth.course.id;
    },
  },
  created() {
  },
  data() {
    return {
      isLoading: false,
      valueStr: "#7957d5",
    };
  },
  methods: {
    save() {
      this.isLoading = true;
      LessonService.setColorToLessons({
        lessonIds: this.lessonIds,
        valueStr: this.valueStr
      }).then(() => {
        this.$emit("close");
        this.$emit('customEvent');
        window.location.reload();
        Toast.open({
          type: "is-success",
          message: "העדכון בוצע בהצלחה!",
          duration: 8000,
        });
      }).catch(() => {
        Toast.open({
          type: "is-danger",
          message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
          duration: 8000,
        });
      })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.datetime .dropdown-content {
  width: 25%;

  .field-body .field {
    flex-direction: row-reverse;
  }
}
</style>
