var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "DialogReportPaid__Component" }, [
    _c("div", { staticClass: "modal-card" }, [
      _c("header", { staticClass: "modal-card-head" }, [
        _c("p", { staticClass: "modal-card-title" }, [
          _vm._v("עדכון סוג לתרגולים")
        ]),
        _c("button", {
          staticClass: "delete",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        })
      ]),
      _c(
        "section",
        { staticClass: "modal-card-body", staticStyle: { height: "500px" } },
        [
          _c(
            "div",
            { staticClass: "DialogReportPaid__Component__Loading" },
            [
              _c(
                "b-field",
                { attrs: { label: "סוג" } },
                [
                  _c("FieldInlineSelect", {
                    attrs: {
                      filterable: true,
                      clearable: true,
                      options: [
                        {
                          Value: 0,
                          Text: "רגיל"
                        },
                        {
                          Value: 1,
                          Text: "חוק מלא"
                        },
                        {
                          Value: 2,
                          Text: "חוק דינאמי"
                        },
                        {
                          Value: 3,
                          Text: "מיקודית"
                        },
                        {
                          Value: 4,
                          Text: "סימולציה"
                        }
                      ]
                    },
                    model: {
                      value: _vm.valueInt,
                      callback: function($$v) {
                        _vm.valueInt = $$v
                      },
                      expression: "valueInt"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _c(
        "footer",
        { staticClass: "modal-card-foot" },
        [
          _c("b-button", {
            attrs: {
              loading: _vm.isLoading,
              label: "שמור",
              type: "is-primary"
            },
            on: { click: _vm.save }
          }),
          _c("b-button", {
            attrs: { disabled: _vm.isLoading, label: "סגור" },
            on: {
              click: function($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }