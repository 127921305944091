<template>
  <div class="DialogReportPaid__Component">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">עדכון סוג לתרגולים</p>
        <button type="button" class="delete" @click="$emit('close')" />
      </header>
      <section style="height: 500px" class="modal-card-body">
        <div class="DialogReportPaid__Component__Loading">
          <b-field label="סוג">
            <FieldInlineSelect :filterable="true"
              :clearable="true" :options="[
                {
                  Value: 0,
                  Text: 'רגיל'
                },
                {
                  Value: 1,
                  Text: 'חוק מלא'
                },
                {
                  Value: 2,
                  Text: 'חוק דינאמי'
                },
                {
                  Value: 3,
                  Text: 'מיקודית'
                },
                {
                  Value: 4,
                  Text: 'סימולציה'
                }
              ]" v-model="valueInt" />
          </b-field>
        </div>
      </section>
      <footer class="modal-card-foot">
        <b-button :loading="isLoading" @click="save" label="שמור" type="is-primary" />
        <b-button :disabled="isLoading" label="סגור" @click="$emit('close')" />
      </footer>
    </div>
  </div>
</template>

<script>
import LessonService from "@/services/LessonService";
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { ToastProgrammatic as Toast } from "buefy";

export default {
  name: "AssignGroupsToStudents",
  props: ["exerciseIds", "onSuccess", "store"],
  components: {
    // vSelect,
    FieldInlineSelect
  },
  created() {
  },
  data() {
    return {
      isLoading: false,
      valueInt: null,
    };
  },
  methods: {
    save() {
      this.isLoading = true;
      LessonService.setTypeToExercises({
        exerciseIds: this.exerciseIds,
        valueInt: this.valueInt
      }).then(() => {
        this.$emit("close");
        this.$emit('customEvent');
        window.location.reload();
        Toast.open({
          type: "is-success",
          message: "העדכון בוצע בהצלחה!",
          duration: 8000,
        });
      }).catch(() => {
        Toast.open({
          type: "is-danger",
          message: "לא היה ניתן לבצע את הפעולה, יש לפנות לתמיכה הטכנית.",
          duration: 8000,
        });
      })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.datetime .dropdown-content {
  width: 25%;

  .field-body .field {
    flex-direction: row-reverse;
  }
}
</style>
